<!--
 * @Author: Will
 * @Date: 2023-08-18 10:48:32
 * @LastEditors: Will
 * @LastEditTime: 2024-04-09 20:23:02
-->
<template>
  <div class="licence">
    <img class="pic" src="../assets/image/licence.png" alt="licence" />
    <p class="p1">当前部署的系统未获得供应的许可，不能进行访问！</p>
    <p class="p2">— 请提供下面机器码给供应商以获取软件使用权限码 —</p>
    <p class="p3">联系电话：<span>4000089610</span></p>
    <div class="p4">
      当前机器码：<span>{{ licenceData.machineCode }}</span>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getLicence } from "@/api/user";
export default {
  setup() {
    const licenceData = ref({});
    getLicence().then((res) => {
      if (res.ret == 0) {
        licenceData.value = res.data;
      }
    });

    return {
      licenceData,
    };
  },
};
</script>

<style lang="less" scoped>
.licence {
  padding-top: 90px;
  text-align: center;
  .pic {
    width: 332px;
    height: 256px;
  }
  .p1 {
    font-size: 20px;
    line-height: 26px;
    color: #272727;
    margin-top: 30px;
  }
  .p2 {
    font-size: 16px;
    line-height: 22px;
    color: #999999;
    margin-top: 16px;
  }
  .p3 {
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-top: 16px;
    span {
      color: #f5ab45;
    }
  }
  .p4 {
    display: inline-block;
    padding: 15px 18px;
    height: 50px;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.04), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-top: 16px;
    span {
      font-weight: 500;
      color: #3370ff;
    }
  }
}
</style>
